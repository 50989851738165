// Import remark
import Api from 'remark/src/remark/api';
import polyfills from 'remark/src/polyfills';
import styler from 'remark/src/remark/components/styler/styler';

// Expose API as `remark`
var remark = new Api();

// Apply polyfills as needed
polyfills.apply();

// Apply embedded styles to document
styler.styleDocument();

// Import animate.css
//import 'animate.compat.css';

// Deal with Mermaid
import mermaid from 'mermaid';

function setupRemark() {
    var slideshow = remark.create({
        ratio: '16:9',
        sourceUrl: '/presentation.md',
        highlightStyle: 'monokai',
        highlightLines: true,
        countIncrementalSlides: false
    });

    mermaid.initialize({
        startOnLoad: false,
        cloneCssStyles: false
    });

    function initMermaid(s) {
        var diagrams = document.querySelectorAll('.mermaid');
        var i;
        for (i = 0; i < diagrams.length; i++) {
            if (diagrams[i].offsetWidth > 0) {
                mermaid.init(undefined, diagrams[i]);
            }
        }
    }
    slideshow.on('afterShowSlide', initMermaid);
    initMermaid(slideshow.getSlides()[slideshow.getCurrentSlideIndex()]);
}


// Reload away
if (module.hot) {
    module.hot.accept(function () {
        //window.location.reload();
        console.log("reloading")
        setupRemark();
    });
}

setupRemark();


//slideshow.on("afterShowSlide", s => {
//    const [slide] = document.getElementsByClassName("remark-visible");
//    mermaid.init(Array.from(slide.getElementsByClassName("mermaid")));
//  });
//  
