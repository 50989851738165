const getStyles = () =>
  `
  .commit-id,
  .commit-msg,
  .branch-label {
    fill: lightgrey;
    color: lightgrey;
    font-family: 'trebuchet ms', verdana, arial;
    font-family: var(--mermaid-font-family);
  }
`;

export default getStyles;
